.info-message {
    background-color: #ffb7004a;
    color: black;
    margin: 10px;
}


.info-container {
    padding: 10px;
    font-size: 13px;
    border-radius: 12px;
    margin-bottom: 10px;
}