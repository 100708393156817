.border-cols {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
}

.title {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}