*::selection {
  background: deepskyblue !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.Relative {
  position: relative !important;
}

label {
  margin: 0 !important;
}

/* --------------------------------------- */

.Card {
  background-color: white;
  margin: 1rem 0;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.miniCard {
  padding: 8px;
  border-radius: 8px;
}

.wAuto {
  width: inherit !important;
}

.GlobalStructure {
  margin: 16px 32px;
}

.w100 {
  width: 100%;
}

.w75 {
  width: 75%;
  margin: auto !important;
}

.w50 {
  width: 50%;
}

.w25 {
  width: 25%;
}

.P0 {
  padding: 0 !important;
}

.P1 {
  padding: 8px;
}

.PA1 {
  padding: 8px 0 0 0;
}

.PL {
  padding-left: 8px;
}

.PID {
  padding: 0 8px !important;
}

.PID2 {
  padding: 6px 16px !important;
}

.M-1 {
  margin: -8px;
}

.MT-1 {
  margin-top: -8px !important;
}

.M-2 {
  margin-top: -12px !important;
}

.MB1 {
  margin-bottom: 8px;
}

.MB-1 {
  margin-bottom: -8px !important;
}

.ML1 {
  margin-left: 8px;
}

.Sombra {
  box-shadow: 0 8px 15px rgba(82, 77, 100, 0.1);
}

.RemueveSombra {
  box-shadow: none !important;
}

.Bordeadito {
  border: 1px solid #f3f3f3;
}

.M0 {
  margin: 0 !important;
}

.MT1 {
  margin: 8px 0 0 0;
}

.M1 {
  margin: 8px !important;
}

.M2 {
  margin: 16px;
}

.P1 {
  padding: 8px !important;
}

.PT0 {
  padding-top: 0 !important;
}

.MT1 {
  margin-top: 8px !important;
}

.MR1 {
  margin-right: 8px !important;
}

.MT2 {
  margin-top: 16px !important;
}

.NotToHigh {
  max-height: 100px !important;
  overflow: hidden;
}

.PV0 {
  padding: 0 8px;
}

.Columna {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.RowCenter {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.ColFlex {
  display: flex;
  flex-direction: column !important;
}

.AlignStart {
  align-items: flex-start;
}

.AlignEnd {
  align-items: flex-end;
}

.AlignCenter {
  align-items: center !important;
}

.AlignEnd {
  align-items: flex-end !important;
}

.SpaceBetween {
  justify-content: space-between;
}

.SpaceEvenly {
  justify-content: space-evenly;
}

.SpaceAround {
  justify-content: space-around;
}

.JustifyStart {
  justify-content: flex-start;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyEnd {
  justify-content: flex-end;
}

.RowWraped {
  flex-wrap: wrap;
}

body {
  background: #ebebeb !important;
  min-height: 100vh;
  /* #fafafa
  background: #ebebeb !important;
  background-image: url('https://images.pexels.com/photos/1029604/pexels-photo-1029604.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  background-position: center;
  background-size: cover;
  */
}

/* Ajusta el tamaa?o del layout general*/
.main-content .main-content-container.container-fluid {
  min-height: calc(100vh - 10rem);
}

.ant-tag::first-letter {
  text-transform: capitalize;
}

.compraResumenLista .ant-tag {
  color: white !important;
}

/* Scroll, elimina las barras y asi evita conflicto con Modals al abrirse. ()*/
*::-webkit-scrollbar-track {
  background-color: whitesmoke;
  border-radius: 0 0 8px 8px;
}

body::-webkit-scrollbar {
  /* display: none; */
}

body::-webkit-scrollbar-thumb {
  background: greenyellow !important;
}

.ant-table-body::-webkit-scrollbar {
  /* Añade Scrollbar UNICAMENTE a las tablas de Antd */
  width: 100vw;
  height: 8px !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: darkgray !important;
  border-radius: 8px;
}

/* 100px , 130px, 150px, 180px, 200px, 250px */

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(1),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(1) {
  /* Ajuste del Num de Pedido*/
  width: 150px !important;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(2),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(2) {
  /* Fecha de entrega*/
  width: 250px !important;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(3),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(3) {
  /* Estado */
  width: 150px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(4) {
  /* Tipo de Problema*/
  width: 200px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(6),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(4) {
  /* Cliente*/
  max-width: 250px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(5) {
  /*Petshop comentario */
  width: 250px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(7),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(5) {
  /* Domicilio */
  width: 300px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(8),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(6) {
  /* Petshop */
  width: 300px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(9) .listaTablaPedidoItem,
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(7) ul {
  /* Productos */
  list-style: initial;
  list-style-type: circle;
  font-size: 12px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(10),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(8) {
  /* Monto */
  width: 150px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(11),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(9) {
  /* Medio */
  width: 150px;
  text-transform: capitalize;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(12),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(10) {
  /* fecha de creacion */
  width: 150px;
}

.TablasPanelAtencionSolucionar .ant-table-tbody tr td:nth-child(13),
.TablasPanelAtencion .ant-table-tbody tr td:nth-child(11) {
  /* nota */
  width: 300px;
  font-size: 12px;
}

/* Estilos para listas dentro de la Tabla de Pedido del Dash donde se listen mas de un producto*/
.listaTablaPedidoItem {
  list-style: none;
  padding: 0 1rem;
}

.GridDos {
  display: grid;
  width: 100%;
  grid-template-columns: 49% 49%;
  align-items: flex-start;
  justify-content: space-between;
}

.GridSeis {
  display: grid;
  width: 100%;
  grid-template-columns: 15.5% 15.5% 15.5% 15.5% 15.5% 15.5%;
  justify-content: space-between;
}

.GridSiete {
  display: grid;
  width: 100%;
  grid-template-columns: 13.28% 13.28% 13.28% 13.28% 13.28% 13.28% 13.28%;
  justify-content: space-between;
}

.GridOcho {
  display: grid;
  width: 100%;
  grid-template-columns: 13.28% 13.28% 13.28% 11% 11% 11% 11% 11%;
  justify-content: space-between;
}

/* Grid de tres columnas para la cancelacion de la compra*/
.GridDosTerceos {
  display: grid;
  width: 100%;
  grid-template-columns: 33% 66%;
  column-gap: 8px;
  margin: auto;

  width: 100%;
  text-align: center;
}

.GridDosTerceos-invert {
  display: grid;
  width: 100%;
  grid-template-columns: 66% 33%;
  column-gap: 8px;
  margin: auto;

  width: 100%;
}

.GridTres {
  display: grid;
  width: 100%;
  grid-template-columns: 32% 32% 32%;
  column-gap: 2%;
}

.GridCuatro {
  display: grid;
  width: 100%;
  grid-template-columns: 24% 24% 24% 24%;
  justify-content: space-between;
}

.GridDosOctavos {
  width: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: 24% 74%;
  column-gap: 2%;
  align-items: flex-start;
  margin: 0 auto;
}

.GridDosOctavos-invert {
  width: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: 74% 24%;
  column-gap: 2%;
  align-items: flex-start;
  margin: 0 auto;
}

.GridDos fieldset,
.GridTres fieldset,
.GridCuatro fieldset,
.GridCinco fieldset,
.GridSeis fieldset,
.GridSiete fieldset,
.GridOcho fieldset,
.GridDosTerceos fieldset,
.GridDosOctavos fieldset {
  width: 100%;
  margin: 8px 0 0 0;
}

.GridDiez {
  display: grid;
  width: 100%;
  grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9% 9%;
  column-gap: 8px;
  justify-content: space-between;
  align-items: center;
}

.ContenedorFull {
  background-color: white;
  margin: 1rem;
  padding: 8px;
  border-radius: 8px;
}

.TextCenter {
  text-align: center;
}

.TextEnd {
  text-align: right;
}

.SolucionarPedidos h3 {
  font-size: 1.5rem;
  text-align: center;
}

.TarjetaRoja {
  width: 100;
  min-height: 50vh;
  margin: 0 0.5rem;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-content: center;
  background-color: salmon;
  border-radius: 15px;
}

.TarjetaRoja h5 {
  color: white;
}

.TarjetaRoja button {
  background-color: white;
  border: none;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 5px;
  width: 96%;
  border-radius: 15px;
  font-weight: bold;
}

.TarjetaApagada {
  transition: all 0.3s;
  background-color: whitesmoke;
  color: gainsboro;
  position: relative;
}

.TarjetaApagada h5 {
  color: lightgrey;
}

.TarjetaApagada::before {
  position: absolute;
  content: "";
  min-width: 100%;
  min-height: 100%;
  background-color: transparent;
  cursor: no-drop;
  left: 0;
}

.TarjetaApagada li {
  color: lightgrey !important;
}

.TarjetaRoja .ListaHorarios {
  list-style: none;
  padding: 0;
}

.TarjetaRoja .ListaHorarios li {
  transition: all 0.3s;

  background-color: whitesmoke;
  color: darkslategrey;
  margin: 5px;
  padding: 0.5rem;
  /*  height: 2.4rem;*/
  overflow: hidden;
  border-radius: 5px;
}

.ListaHorarios li:hover {
  height: auto;
}

.TarjetaRoja .ListaHorarios li ul {
  list-style: none;
  padding: 0;
  margin-top: 1rem;

  text-align: left;
}

.TarjetaRoja .ListaHorarios li ul li:hover {
  cursor: pointer;
  padding-left: 1.5rem;
  background-color: grey;
  color: white;
}

/* Alineacion general para las tablas de Antd*/

.ant-table-column-has-actions,
th,
td {
  text-align: center !important;
  cursor: pointer;
}

.ant-table-fixed tr td ul {
  text-align: left !important;
}

.ant-table-fixed thead th {
  background-color: transparent !important;
}

tr .ant-tag {
  margin-top: 0.5rem;
  margin-bottom: -1rem;
}

/* alineacion general para iconos*/

i svg {
  margin-bottom: 0.5rem;
}

.iconos-fix .botonItem {
  padding: 0;
  min-width: 32px;
  height: 32px;
  text-align: center;

  color: #00bfff;
  border: 1px solid #00bfff;
}

.iconos-fix .botonItem svg {
  margin: 0;
}

.ant-collapse {
  margin-bottom: 1rem;
  background-color: #fff;
  font-size: 18px;
  border-radius: 6px;
}

.ant-modal-body {
  padding: 16px;
}

.ant-modal-body button span {
  line-height: 2rem;
}

.ant-modal-footer button {
  /* realinea los iconos y los textos dentro de los botones en el footer de los modals */
  line-height: 2rem;
}

/* GEORADAR */

.GeoRadar div {
  left: 0;
  height: 100%;
}

.GeoRadar a,
.GeoRadar a div,
.GeoRadar .gmnoprint {
  display: none !important;
}

/* lista de delegacion de compra, Pets que no cumplen condiciones*/
.mandarin label .custom-control-description div {
  margin-top: -1.5rem;
}

.mandarin label .custom-control-label::before,
.mandarin label .custom-control-label::after {
  margin-top: 1rem;
  /*alinea el radio al centro del item*/
}

/* Estilos para el NavItem de la SideBar donde se indica la version actual del panel */
.version {
  font-size: 14px;
  line-height: 18px;
  color: deepskyblue;
  text-align: center;
}

.version:hover {
  cursor: pointer;
  border: none;
}

/*-----------------*/

.consultaItem {
  list-style: none;
  display: flex;
  margin-left: -3rem;
}

.consultaItem li {
  padding: 0.5rem;
}

.consultaItem li ul {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  list-style: none;
}

.consultaItem .tiene {
  margin-right: 0.5rem;
  min-width: 33%;
  background-color: whitesmoke;
  border-radius: 5px;
}

.tiene li {
  background-color: mediumaquamarine;
  color: white;
  margin-right: 0.45rem;
  margin-top: 0.5rem;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  font-size: 12px;
}

.no {
  background-color: rgb(194, 194, 194) !important;
}

.no li {
  background-color: rgb(223, 223, 223);
  color: grey;
}

/*-- Loader --*/
.loader,
.Loader {
  color: cornflowerblue !important;
  font-size: 2rem;
  display: block !important;
  margin: 64px auto;
  animation: giro360 0.5s infinite linear;
}

.Giro360 {
  animation: giro360 0.5s infinite linear;
}

@keyframes giro360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Rota270 {
  transform: rotate(270deg);
}

/*--- Boton de masProductosBtn geo radar ---*/

.masProductosBtn {
  position: absolute;
  top: 5rem;
  right: 1rem;

  border: 1px solid gray;
  background-color: white;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

/* --- Indicadores del Panel (A solucionar, Pendiente de pago, etc Tambien son los colores indicativos de estado en CompraResumen...) ---- */
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-danger,
.bg-danger {
  background-color: tomato !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.btn-outline-danger {
  border: 1px solid tomato !important;
  color: tomato !important;
}

.btn-outline-danger:hover {
  background-color: tomato !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.rojo {
  background: linear-gradient(-45deg, crimson, tomato, tomato);
  color: white;
  border: none !important;
}

.violeta {
  background: linear-gradient(-45deg, rgb(153, 20, 220), rgb(116, 22, 167), rgb(111, 14, 133));
  color: white;
  border: none !important;
}

.rojo h4 {
  color: white;
}

.procesando {
  background-color: orange !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.naranja {
  background: linear-gradient(-45deg, orangered, orange, orange);
  color: white;
  border: none !important;
}

.naranja h4 {
  color: white;
}

.bg-warning {
  min-width: 80px;
  /* Para los tags dentro de Mega Buscador, no afecta a los demas elementos con esta clase*/
  text-align: center;
  /* Para los tags dentro de Mega Buscador, no afecta a los demas elementos con esta clase*/
  background-color: gold !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.amarillo {
  background: linear-gradient(-45deg, orange, gold, gold);
  color: white;
  border: none !important;
}

.amarillo h4 {
  color: white;
}

.btn-primary,
.ant-btn-primary,
.preparando {
  background-color: deepskyblue !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.btn-outline-primary {
  border: 1px solid deepskyblue !important;
  color: deepskyblue !important;
}

.btn-outline-primary:hover {
  background-color: deepskyblue !important;
  border: 1px solid deepskyblue !important;
  color: white !important;
}

.azul {
  background: linear-gradient(-45deg, dodgerblue, deepskyblue, deepskyblue);
  color: white;
  border: none !important;
}

.azul h4 {
  color: white;
}

.enCamino {
  background-color: deepskyblue !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.entregado,
.bg-success {
  background-color: #5cb85c !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.verde {
  background: linear-gradient(-45deg, #43a143, #5cb85c, #5cb85c);
  color: white;
  border: none !important;
}

.verde h4 {
  color: white;
}

.split {
  background-color: deeppink !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.rosado {
  background: linear-gradient(-45deg, mediumvioletred, deeppink, deeppink);
  color: white;
  border: none !important;
}

.rosado h4 {
  color: white;
}

.purpura {
  background: linear-gradient(-45deg, purple, blueviolet, blueviolet);
  color: white;
  border: none !important;
}

.purpura h4 {
  color: white;
}

.blanco {
  background: linear-gradient(-35deg, whitesmoke, white, whitesmoke);
  border: 1px solid transparent !important;
  color: lightslategray !important;
}

.blanco .stickersBox i {
  color: lightslategray;
  /* Colorea los Stickers dentro de una compra que se aca de iniciar */
}

.cancelado {
  min-width: 80px;
  /* Para los tags dentro de Mega Buscador, no afecta a los demas elementos con esta clase*/
  text-align: center;
  /* Para los tags dentro de Mega Buscador, no afecta a los demas elementos con esta clase*/
  background-color: lightslategrey !important;
  border: 1px solid transparent !important;
  color: white !important;
}

.gris {
  background: linear-gradient(-45deg,
      darkslategrey,
      lightslategrey,
      lightslategrey);
  color: white;
  border: none !important;
}

.gris h4 {
  color: white;
}

.textoGris {
  color: lightslategray !important;
}

.textosBancos {
  cursor: pointer !important;
  color: white !important;
}

.textosLink {
  cursor: pointer !important;
  color: deepskyblue !important;
}

.textosCeleste {
  color: deepskyblue !important;
}

.textosRojo {
  cursor: pointer !important;
  color: tomato !important;
}

.textosLink:hover {
  text-decoration: underline;
}

.gris,
.rojo,
.naranja,
.amarillo,
.azul,
.verde,
.blanco,
.rosado,
.purpura {
  padding: 15px 0 12px 0;
}

.iconPanel {
  font-size: 32px;
}

/*-------------- :D Tiles indicadores del panel ----------------------*/

.TileConteiner {
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow-y: scroll;
  margin-bottom: -20px;

  transition: all 0.5s;
}

.TileConteiner::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.TileConteiner article,
.indicador {
  display: block;
  min-width: 218px;
  padding: 16px 0;
  border-radius: 16px;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);

  display: flex;
  flex-flow: column;
  align-items: center;

  transition: all 0.3s;

  margin-right: -5rem;
  margin-left: 0;

  transform-style: preserve-3d;
  transform-style: preserve-3d;
  transform: perspective(700px) rotateY(0deg) scale(0.9);

  position: relative;
  overflow: hidden;

  backdrop-filter: blur(50px);
}

.indicador:hover {
  z-index: 1;
  margin-right: -2rem;
  margin-left: 0.5rem;
  transform: perspective(700px) rotateY(0deg);
  cursor: pointer;
}

div.container-fluid .TileConteiner,
div.container .TileConteiner {
  width: auto !important;
}

.indicador:after {
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 200%;
  height: 200%;
  opacity: 0;

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0.13) 0%,
      rgba(255, 255, 255, 0.13) 77%,
      rgba(255, 255, 255, 0.5) 92%,
      rgba(255, 255, 255, 0) 100%);
}

.indicador:hover:after {
  opacity: 1;
  top: -30%;
  left: -30%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}

.indicador:active {
  transition: all 0.5s;
  transform: scale(1.05);
}

.disabledPanel {
  cursor: default;
  background-color: #cfcfcf;
  padding: 15px 0 12px 0;
}

.disabledPanel .pill {
  background-color: #ddd;
}

.pill {
  background-color: white;
  color: darkslategrey;
  margin: 0.2rem auto;
  padding: 0 1rem;
  border-radius: 15px;
}

.petshopTile h6 {
  font-size: 12px !important;
}

@keyframes apilarse {
  0% {
    margin-right: 1rem;
    /*transform: perspective(0) rotateY(0deg);*/
  }

  100% {
    margin-right: -4rem;
  }
}

/*-------------- :D Nuevas tablas del Panel ----------------------*/
.ShowElement {
  transform: scale(1) translateY(0) translateX(0) !important;
  opacity: 1 !important;
  z-index: 1;
  max-height: 10000px;
}

.TableBox {
  border-radius: 8px;
}

.TableBox article {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 20px -8px #0000005b;
  margin-bottom: 2rem;
}

.TableBox article .TableBoxHeader,
article .TableBoxHeader {
  /*background-image: linear-gradient(to right bottom, white, whitesmoke);*/
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TableBoxHeader {
  border-radius: 8px 8px 0 0;
}

.TableBox article div .ShowElement {
  transform: scale(1);
  right: 16px;
}

/* :D Compra resumen*/

.CompraResumenBox .RowCenter {
  background-color: transparent;
  padding: 0;
  margin: 2rem 1.5rem -1rem 1.5rem;
  justify-content: flex-start;
}

.CompraResumenBox .RowCenter button {
  width: 32px;
  height: 32px;
  border: none;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 0 20px -5px #0000005b;
  transform: scale(1.3);

  transition: all 0.5s;
  color: dimgray;
}

.CompraResumenBox .RowCenter button svg {
  transform: scale(1.5);
  margin-left: -2px;
}

.CompraResumenBox .RowCenter button:active {
  outline: none;
  transform: scale(1);
}

/* :D Felicidad del cliente --------------------------------*/

.PanelLista ul {
  list-style: none;
  padding: 0.5rem;
  margin: 0;
}

.PanelLista li {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 1rem;
  border-bottom: 1px solid whitesmoke;
}

/*--------------Semaforo que informa acerca de las delegaciones en Compra Seleccionada----------------------*/
.semaforo-btn {
  position: absolute;
  z-index: 1;
  top: 4.6rem;
  left: 1rem;
  cursor: pointer;

  color: white;
  text-transform: uppercase;
  font-size: 14px;
}

.semaforo {
  z-index: -1;
  transition: all 0.5s;
  position: absolute;
  top: -2.5rem;
  right: 1rem;

  background-color: white;

  text-align: center;
  padding: 0.5rem 2rem;
  border-radius: 15px 15px 0 0;
}

.semaforo p {
  margin: 0;
  padding-bottom: 0.5rem;
}

.MuestraInfoDelegacion {
  top: -4.5rem;
}

/*-------- Telefono del pet de compra resumen aert --------*/

.TelefonoPet {
  background-color: transparent;
  width: 130px;
}

.TelefonoPet button {
  border-radius: 8px;
  border: 2px solid grey;
  color: grey;
  background-color: white;
  width: 32px;
  height: 32px;
  transition: all 0.3s;
}

.TelefonoPet button:hover {
  border: 2px solid #1fa9e7;
  color: #1fa9e7;
}

/*-------- Pesta?a con datos del perfil --------*/
.UsuarioPerfil {
  background-color: transparent;
  width: calc(100% + 3rem);
  margin: 0 -1.5rem;
  padding-top: 2rem;
}

.UsuarioPerfil div {
  margin: 1rem 0.5rem;
  display: flex;
  align-items: center;
  background-color: transparent;
  height: 64px;
  padding-top: 8px;
  border-bottom: 2px solid grey;
}

.UsuarioPerfil div h5 {
  background-color: transparent;
  width: 63%;
}

.UsuarioPerfil img {
  width: 44px;
  height: 44px;
  margin-right: 0.5rem;
  opacity: 0.5;
  margin-bottom: 8px;
}

.UsuarioPerfil div button {
  background-color: transparent;
  width: 40px;
  height: 40px;
  border: 2px solid grey;
  color: grey;
  box-sizing: border-box;
  margin-left: 4px;
  margin-bottom: 12px;
  border-radius: 12px;
  transition: all 0.3s;
  outline: 0 !important;
}

.UsuarioPerfil div button:hover {
  border: 2px solid #1fa9e7;
  color: #1fa9e7;
}

.UsuarioPerfil form {
  background-color: transparent;
  height: calc(100vh - 240px);
  padding: 0 1rem;
  position: relative;
}

.UsuarioPerfil ul {
  background-color: transparent;
  display: flex;
  list-style: none;
  justify-content: space-around;
  padding: 0.5rem;
  margin-top: -1rem;
}

fieldset>input,
fieldset select,
textarea {
  border: 1px solid rgb(221, 221, 221);
  padding: 8px;
  border-radius: 4px;
  outline: none;
  /* resize: none; */
}

fieldset legend {
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: 600;
}

fieldset input[type="checkbox"],
fieldset input[type="radio"] {
  width: auto;
  margin: 0 8px;
}

fieldset input[type="tel"] {
  text-align: right;
}

.Emojis {
  text-decoration: none;
  color: deepskyblue;
  padding: 8px;
  display: block;
}

.UsuarioPerfil form fieldset {
  margin-bottom: 1rem;
}

.UsuarioPerfil form fieldset input {
  border: none;
  background-color: whitesmoke;
  padding: 0.5rem;
  border-radius: 8px;
  outline: none;
}

.UsuarioPerfil form fieldset:nth-child(1) input,
.UsuarioPerfil form fieldset:nth-child(2) input,
.UsuarioPerfil form fieldset:nth-child(4) input {
  width: 370px !important;
}

.UsuarioPerfil form fieldset:nth-child(3) div {
  background-color: transparent;
  display: grid;
  width: 100%;
  grid-template-columns: 44px 54px 272px;
  border-bottom: none;
  margin: 0;
  margin-top: -1rem;
}

.UsuarioPerfil form fieldset:nth-child(3) div input {
  background-color: whitesmoke;
  width: 100% !important;
  padding: 0.5rem 0;
  text-align: center;
}

.UsuarioPerfil form fieldset:nth-child(3) div input:nth-child(1) {
  background-color: whitesmoke;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.UsuarioPerfil form fieldset:nth-child(3) div input:nth-child(2) {
  background-color: whitesmoke;
  border-radius: 0;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
}

.UsuarioPerfil form fieldset:nth-child(3) div input:nth-child(3) {
  background-color: whitesmoke;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.UsuarioPerfil form fieldset:nth-child(4) input {
  background-color: #ddd;
}

.UsuarioPerfil form fieldset:nth-child(4) input:hover {
  cursor: not-allowed;
}

.UsuarioPerfil input[type="number"]::-webkit-inner-spin-button,
.UsuarioPerfil input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.UsuarioPerfil input[type="number"] {
  -moz-appearance: textfield;
}

.UsuarioPerfil form fieldset:nth-child(5) {
  background-color: transparent;
  position: absolute;
  bottom: -50px;
  left: 0;
  height: 44px;
  width: 100%;
}

.UsuarioPerfil form fieldset:nth-child(5) button {
  background-color: white;
  border-radius: 8px;
  border: 2px solid grey;
  height: 44px;
  width: 45%;
  margin: 0 2.5%;
  font-size: 1.2rem;
  outline: none;
  transition: all 0.3s;
}

.UsuarioPerfil form fieldset:nth-child(5) button:first-child {
  border: 2px solid transparent;
  color: gray;
}

.UsuarioPerfil form fieldset:nth-child(5) button:first-child:hover {
  color: tomato;
}

.UsuarioPerfil form fieldset:nth-child(5) button:last-child:hover {
  border: 2px solid #1fa9e7;
  color: #1fa9e7;
}

fieldset input,
fieldset select,
fieldset textarea {
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  padding: 0 8px;
  line-height: 34px;
  border-radius: 4px;
  outline: none;
}

/*----------colorea el texto de la descricion de los steps -----------*/
.ant-steps-item-description {
  font-size: 12px !important;
  padding: 0 0 1rem 0 !important;
  color: cornflowerblue !important;
}

/*-- Lista de resumen de cambios en la compr del cliente --*/

.CompraSumaTotal {
  margin-top: 1rem;
  list-style: none;
  padding: 0;
  color: grey;
  font-size: 14px;
}

.CompraSumaTotal li {
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.CompraSumaTotal li .no {
  font-weight: bold;
  background-color: transparent !important;
}

.CompraSumaTotal li .si {
  font-weight: bold;
  background-color: transparent !important;
}

.CompraSumaTotal li:last-child {
  border-top: 1px dashed grey;
  padding-top: 1rem;
  font-weight: bold;
}

.CompraSumaTotal .compraAccionBtn {
  height: 1.9rem;
  width: 1.9rem;
  font-size: 1.2rem;
  line-height: 1.85rem;
  margin-left: 0.5rem;
  margin-right: -0.5rem;
  color: deepskyblue;
  border: 1px solid deepskyblue;
  border-radius: 50%;
}

.CompraSumaTotal .compraAccionBtn:active {
  background-color: deepskyblue;
  color: white;
}

/*Lista con los resultados de la busqueda, aparece cuando se edita los productos y se quieren agregar mas*/
.list-group {
  width: calc(100% + 32px);
  margin-left: -16px;
}

.resultadosBusquedaLista {
  transition: all 0.5s;
  display: grid;
  width: 100%;
  grid-template-columns: 50px calc(100% - 166px) 100px;
  align-items: center;

  margin-bottom: 16px;
}

.resultadosBusquedaLista img {
  max-height: 50px;
  margin: auto;
}

.resultadosBusquedaLista p {
  line-height: auto;
}

/* Bienvenida, animacion de cierre */

.bienvenida {
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 20vw;
  transform: translateY(-50%);

  min-width: 75vw;
  overflow: hidden;

  box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 350px;
  border-radius: 15px;
  transition: 1s;
}

.cerrado {
  box-shadow: transparent 0 0 0 20000px;
  background-color: transparent;

  transform: translate(0, -1000px);
}

.bienvenida .saludo {
  min-height: 100%;
  padding: 1rem;
}

.gif {
  width: 100%;
}

.clima iframe {
  border: none;
  margin: auto;
  height: 200px;
  width: 250px;
}

/* fondo de pantalla en vista de Login */

.cardLogin {
  max-width: 350px;
  box-shadow: 50px 0 50px rgba(0, 0, 0, 0.5);
}

.cardLogin .StoreImage {
  max-width: 45vw;
}

.fondoLogin {
  background-color: white;
  min-height: 100vh;
  position: relative;
}

.LoginForm {
  width: 100%;
  padding: 8px;
  z-index: 10;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 67vw) !important;
}

.navLogo {
  cursor: pointer;
}

.LoginForm .navLogo {
  max-width: 80%;
  margin: 32px 0 32px 10%;
  filter: saturate(150%);
}

.LoginForm button {
  background-color: deepskyblue;
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 4px;
  margin-bottom: 16px;
  color: white;
  font-size: 18px;
}

.LoginForm h1 {
  font-size: 18px;
  text-align: center;
}

.falloLogin {
  margin-bottom: 8px;
  border-radius: 4px;
  text-align: center;
  color: red;
  padding: 12px;
}

@media screen and (min-width: 80px) {
  .cardLogin {
    background-color: white;
    width: 100vw;
  }

  .fondoLogin {
    display: flex;
    flex-direction: row-reverse;
  }

  .fondoLogin .LoginImage {
    width: calc(100vw - 350px);
    height: 100vh;
  }

  .LoginForm::before {
    display: none;
  }
}

/* Modal de editar producto, muestra un row donde se incluye un input para agregar aumentar la cantidad y un label con la cantidad original */

.editarProductosCadaItem {
  width: 100%;

  display: grid;
  width: 100%;
  grid-template-columns: 44px 44px calc(100% - 238px) 32px 70px;
  column-gap: 8px;
  align-items: center;
  margin: 16px 0;
}

.editarProductosCadaItem img {
  max-height: 44px;
}

.editarProductosCadaItem svg {
  text-align: center;
}

.editarProductosCadaItem span {
  text-align: right;
  font-weight: bold;
}

.itemAumentaProducto {
  width: 50px;
  height: 2rem;
  text-align: left;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding-left: 5px;
}

.itemAumentaProducto:focus {
  outline: none;
}

.EditarCompraResumen {
  text-align: right;
  background-color: whitesmoke;
  border: 1px solid #ddd;
  padding: 1rem 0;
  border-radius: 5px;
}

.EditarCompraResumen .ant-tag {
  text-align: center;
  min-width: 60px;
  margin-left: 0.5rem;
}

.EditarCompraResumen p {
  padding: 0;
  margin-bottom: 1rem;
}

.itemTachado {
  text-decoration: line-through;
}

/* Compra - Reembolso/Cancelar campo de texto */

.campoTextarea {
  resize: none;
  padding: 0.5rem;
}

.iconoEspejado {
  transform: rotateY(-180deg) translateX(1.3rem) !important;
}

.delegaCompraMailIcono {
  width: 2.2rem;
  height: 2.5rem;
  padding: 0 !important;
  font-weight: bold !important;
}

/* Estilos para la Navbar */

header {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 8px 15px rgba(82, 77, 100, 0.1);
  z-index: 100;
}

.NuevaNavbar nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 4rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.NuevaNavbarChild {
  display: flex;
  align-items: center;
}

.NuevaNavbar img {
  max-width: 5rem;
  margin: 0 18px 0 8px;
  filter: saturate(150%);
}

.NavMenuIcon svg {
  font-size: 1.5rem;
  margin-right: 8px;
  cursor: pointer;
  transition: all 0.5s;
}

.megaBusquedaInput {
  display: flex;
  align-items: center;

  padding: 0 0 0 12px;
  border-radius: 8px;
  background-color: rgb(240, 240, 240);
  width: 330px;
  height: 40px;
}

.megaBusquedaInput input {
  margin-left: 12px;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 4px 0;
  background-color: transparent;

  color: #414141;
  font-size: 18px;
  font-weight: 500;
}

.megaBusquedaInput input::placeholder {
  font-size: 14px;
  text-align: left;
}

.megaBusquedaInput input:focus {
  outline: none;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.NuevaNavbar .AuxiliarBox {
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  animation: FadeIn 0.15s linear;
  transition: all 0.5s;
}

.NuevaSidebar {
  z-index: 2;
  transition: all 0.5s;
  position: absolute;
  top: 5rem;
  left: 1rem;

  list-style: none;
  text-align: center;
  color: #414141;
  box-shadow: 0 8px 15px #0000004d;
  font-weight: normal;
  font-size: 16px;
}

.NuevaSidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.NuevaSidebar li {
  font-size: 14px;
  background-color: white;
  position: relative;
  width: 280px;
  padding: 10px;
  transition: all 0.2s;
}

.NuevaSidebar li:first-child {
  border-radius: 8px 8px 0 0;
}

.NuevaSidebar li:last-child {
  border-radius: 0 0 8px 8px;
}

.NuevaSidebar li:hover {
  cursor: pointer;
  background: #eee;
  color: #1fa9e7;
}

.sidebarItemActivo {
  background: linear-gradient(-45deg, #1fa9e7, deepskyblue, #1fa9e7);
  color: white;
}

.NuevaSidebar li ol {
  display: none;
  list-style: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.NuevaSidebar li:hover ol {
  display: initial;
}

.NuevaSidebar li ol li {
  background-color: #f8f8f8;
  width: 300px;
}

.NuevaSidebar li ol li:first-child {
  border-radius: 0 8px 0 0;
}

.NuevaSidebar li ol li:last-child {
  border-radius: 0 0 8px 0;
}

/* NavUser */

.UserState {
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all 0.3s;
  padding: 8px 12px 8px 0;
  background-color: #fafafa;
}

.UserState svg {
  margin: 0 12px;
}

.UserState h4 {
  font-size: 16px;
  transition: all 0.3s;
  margin: 0;
}

.Logout {
  background-color: transparent;
  width: 32px;
  height: 32px;
  margin-left: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.1);
  transition: all 0.3s;
  cursor: pointer;
}

.Logout:hover {
  box-shadow: 0 0 0 5px rgba(255, 99, 71, 0.2);
}

.Logout svg {
  color: tomato;
  font-size: 28px;
}

/*--- Barra de busqueda ---*/

.resultadosBox {
  transition: all 0.5s;
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.8),
      rgba(116, 116, 116, 0.2));
  position: absolute;
  top: 4rem;
  left: 0;
  width: 100vw;
  height: 100vh;
  list-style: none;
  padding: 1rem;
}

.notificationsBox {
  transition: all 0.5s;
  background: transparent;
  position: relative;
  top: 0;
  height: 80vh;
  list-style: none;
  padding: 1rem;
  overflow-y: scroll;
}

.resultadosBox li,
.notificationsBox li {
  transition: all 0.2s;
  z-index: -1;
  width: 60vw;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: rgb(253, 253, 253);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.5);
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
}

.resultadosBox li:hover {
  background-color: white;
  color: dimgray;
  cursor: pointer;
}

.notificationsBox li:hover h4 {
  color: black;
}

.hideResultadosBuscador {
  transition: all 0.3;
  display: none;
}

/* Splits, items listados en el modal de Compra Split*/
.itemOculto {
  display: none !important;
}

.splitBox h2 {
  width: 100%;
  padding: 16px 0;
  color: dimgray;
}

.splitBox {
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */

  display: flex;
  flex-flow: row wrap;
}

.splitBox article .blanco h3,
.splitBox article .blanco p {
  /* Da color al texto cuando la tarjeta tiene la clase Blanco */
  color: lightslategray !important;
}

.splitBox article {
  background-color: white;

  cursor: pointer;
  position: relative;
  padding: 0 16px;
  border-radius: 16px;
  max-width: 400px;
  margin-right: 16px;
  transition: all 0.5s;

  /*  transform-style: preserve-3d;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform: rotateY(15deg) translateX(64px)
*/
}

.splitBox div {
  border-radius: 16px 16px 0 0;
  margin: 0 -16px;
  padding: 8px;
}

.splitBox h3 {
  display: block;
  color: white;
  text-align: left;
  margin: -1rem;
  padding: 16px;
  line-height: 32px;
  font-size: 20px;
}

.splitBox p {
  text-align: left;
  text-transform: capitalize;
  color: white;
  margin: 0 -16px 16px -16px;
  padding: 8px 16px;
  font-weight: bold;
}

.splitBox b {
  display: grid;
  width: 100%;
  grid-template-columns: 100px auto;

  width: 105%;
  margin: -24px 0 32px -8px;
  background: white;
  border: 1px solid gainsboro;
  color: dimgray;
  padding: 4px 0;
  border-radius: 8px;
}

.splitBox b:last-child {
  margin-bottom: 8px;
}

.splitBox b img {
  max-height: 64px;
  max-width: 48px;
}

.splitBox b span {
  font-size: 12px;
  max-width: 95%;
}

.burbujasVerdes,
.burbujasAmarillas,
.burbujasRojas,
.burbujasNegras {
  position: absolute;
  top: 0;
  left: 20%;
  transform: translateX(-54%);

  width: 200px;
  height: 200px;
  background-color: transparent;
  color: transparent;
}

.burbujasVerdes::before,
.burbujasAmarillas::before,
.burbujasRojas::before,
.burbujasNegras::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-54%);

  bottom: 0;
  min-width: 30px;
  min-height: 30px;
  border: 3px solid white;
  border-radius: 50%;
}

.burbujasVerdes::before {
  background-color: #5cb85c;
}

.burbujasAmarillas::before {
  background-color: gold;
}

.burbujasRojas::before {
  background-color: tomato;
}

.burbujasNegras::before {
  background-color: dimgray;
}

.infoComercialBox {
  display: flex !important;
  flex-flow: column;
  justify-content: space-between;
  border: 2px solid whitesmoke;
  border-radius: 15px;
  height: 300px;
  max-width: 65%;
  margin: 0 auto 2rem auto;
  padding: 1rem;
  font-size: 18px;
  text-align: center;
  transition: all 0.3s;
}

.infoComercialBox:hover {
  border: 2px solid #00bfff;
  color: deepskyblue;
  text-decoration: none;
}

.infoComercialBox img {
  margin: -1rem auto 0.5rem auto;
  max-height: 160px;
  max-width: 140px;
}

.slick-dots li button {
  background-color: dimgrey !important;
}

.filtroPetshop {
  display: grid;
  width: 100%;
  grid-template-columns: auto 100px;
  grid-column-gap: 16px;
  align-items: center;
}

.filtroPetshop input[type="search"] {
  padding: 8px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: dimgray;
}

.filtroPetshop input[type="search"]:focus {
  outline: none;
}

.GridCinco {
  width: 100%;
  display: grid;
  width: 100%;
  grid-template-columns: 19.2% 19.2% 19.2% 19.2% 19.2% !important;
  grid-gap: 1%;
}

.GridCinco div {
  width: 100%;
}

.GridCinco div label {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}

/* --- SEGUIMIENTO --- */

.CompraNotas {
  padding-top: 10vh;
}

.CompraNotas article {
  margin-bottom: 100px;
  box-shadow: #cfcfcf 0 0 10px -2px;
  border-radius: 8px;
  background: linear-gradient(-45deg, white, white, white);
  color: dimgrey;
  font-weight: normal;
  position: relative;
  z-index: 1;
}

.CompraNotas article::before {
  position: absolute;
  content: "";
  top: -100px;
  left: 64px;
  border-left: 2px solid deepskyblue;
  z-index: -1;

  height: 100px;
  width: 32px;
}

.CompraNotas b {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
}

.CompraNotas span {
  margin: 0;
  color: deepskyblue;
  font-size: 16px;
}

.CompraNotas h4 {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-weight: normal;
  margin: 0;
  font-size: 14px;
}

.CompraNotas p {
  padding: 8px 16px;
  line-height: 22px;
}

.petTransferencias {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.tresCol {
  display: grid;
  width: 100%;
  grid-template-columns: 300px 200px 100px;
  grid-gap: 32px;
}

.tresCol label {
  text-align: center;
}

.tresCol div {
  width: 100%;
}

.petTransferenciasBox ul {
  background-color: white;
  box-shadow: gray 5px 5px 20px -5px;
  margin: 2rem 1rem;
  padding: 16px;
  list-style: none;

  border-radius: 16px;
}

.CompraSeleccionada {
  background-color: transparent;
  padding: 2rem;
  width: 100%;
  left: 0%;
}

ol {
  list-style: circle;
}

/*
.marginRight{
  margin-left: .5rem;
}*/

.alert-error {
  background-color: rgba(255, 99, 71, 0.05);
  border: 1px solid tomato;
}

.alert-warning {
  background-color: rgba(255, 217, 0, 0.05);
  border: 1px solid gold;
}

.alert-info {
  background-color: rgba(0, 191, 255, 0.05);
  border: 1px solid deepskyblue;
}

.alert-normal {
  background-color: rgba(211, 211, 211, 0.1);
  border: 1px solid lightgrey;
}

ul.list-style-none {
  list-style: none;
}

li.liBlockProduct {
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

.blockProductProductName {
  font-size: 12px;
}

/*  Formulario  */

.Formulario {
  background-color: white;
  padding: 16px;
  border-radius: 15px;
}

.mapaComandos {
  position: absolute;
  min-width: 480px;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.mapaComandos input[type="button"] {
  padding: 8px 16px;
  margin: 8px 4px;
  color: dimgrey;
}

.mapaComandos input[type="button"]:last-child {
  background-color: #5cb85c;
  color: white;
  border: none;
}

.gmapsZone div {
  margin-top: -22px;
  margin-left: -12px;
}

.DrawerDashboardList {
  background-color: transparent;
  list-style: none;
  padding: 0;
  margin: 0 -1.5rem;
}

.DrawerDashboardList li {
  padding: 0.5rem 1rem;
}

.DrawerDashboardList li h5 {
  margin-bottom: 0;
}

.DrawerDashboardList li b {
  font-weight: normal;
}

.DrawerDashboardList li button {
  border: 2px solid grey;
  background-color: white;
  color: grey;
  width: 42px;
  height: 42px;
  border-radius: 12px;

  margin-left: 8px;
  transition: all 0.3s;
  outline: none;
}

.DrawerDashboardList li button svg {
  font-size: 1.2rem;
}

.CustomOmiButton {
  border: 2px solid grey;
  background-color: white;
  color: grey;
  border-radius: 32px;

  padding: 0.5rem;
  margin: 1rem 0;
  width: 100%;

  font-size: 1.2rem;
  font-weight: normal;
  transition: all 0.3s;
  outline: none;
}

.DrawerDashboardList li button:hover {
  border: 2px solid #1fa9e7;
  color: #1fa9e7;
}

.CustomOmiButton:hover {
  border: 2px solid #1fa9e7;
  color: #1fa9e7;
}

.DrawerShortcut {
  background-color: transparent;
  margin: 0 -1.5rem;
  padding: 0 1.5rem;
}

.DrawerShortcut .UserHappines {
  background-color: transparent;
  overflow: hidden;
  display: flex;
  margin: 0 -1.5rem;
  box-shadow: none;
  padding: 0;
}

.DrawerShortcut .UserHappines li {
  background-color: transparent;
  min-width: 50%;
}

.DrawerShortcut .UserHappines li:last-child {
  background-color: transparent;
  margin-left: -1.5rem;
  border-left: 2px solid #ddd;
}

/* InfowWindows AltaUsuariosMapa.js */
.gm-style .gm-style-iw-c {
  height: 80px !important;
}

.NavNotification {
  background-color: transparent;
  position: absolute;
  left: -40px;
  top: 180%;
  width: 400px;
}

.NavNotification li {
  background-color: white;
  display: block;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
}

.Timeline {
  position: relative;
  z-index: 20;
  margin-top: 32px;
  margin-left: 32px;
}

.Timeline::before {
  position: absolute;
  content: "";
  bottom: -24px;
  right: 212px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 2px;
  height: 12px;
  z-index: 1;
}

/* -----  old css  ----- */

.TabPanel {
  padding: 0;
  overflow: hidden;
}

.TabPanel .TabPanelHead {
  background-color: #f8f8f8;
  display: flex;
}

.TabPanel .TabPanelHead div {
  padding: 12px;
  cursor: pointer;
  transition: all 0.3s;
}

.TabPanelBody {
  padding: 8px;
}

.TabPanelBody div {
  display: none;
  width: 100%;
  min-height: 32px;
}

.TabPanelBody .active {
  display: initial;
}

.TabPanelHead .active {
  box-shadow: 0 -2px 0 deepskyblue inset;
}

.GrupoDeBotones {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ant-popover-inner .botonItem {
  cursor: pointer;
}

.botonItem,
.botonActivado .botonParaDescargarExcel span button {
  min-width: 44px;
  line-height: 24px;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;

  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s;
}

.botonItem i,
.botonActivado i {
  margin: 0 0 -4px 0;
  padding: 0;
  font-size: 16px;
}

.botonItem:hover {
  background-color: #afe4ff66;
  color: #1fa9e7;
  border: 1px solid #1fa9e7;
}

.botonActivado {
  background-color: #00bfff;
  border: 1px solid #00bfff;
  color: white;
}

.editarProductosCadaItem .botonItem {
  font-size: 16px;
}

.TransportistaBox {
  display: flex;
  flex-wrap: wrap;
  padding: 0 !important;
  justify-content: flex-start;
}

.TransportistaBox li {
  width: 22vw;
  margin: 1rem 0.5rem 0.5rem 1.4rem;
  min-height: 200px;
  background-color: plum;
  list-style: none;
}

.Message {
  width: 100%;
  margin: 0 8px;
  padding: 8px 16px;

  border-radius: 4px;
  background-color: #afe4ff66;
  color: deepskyblue;
}

.TagVerde {
  color: #43a143;
  font-weight: bold;
}

.Jumbotron {
  background-color: white;
  max-width: 550px;
  margin: 16px auto;
  padding: 12px 16px;
  border-radius: 8px;
  text-align: center;
}

.Jumbotron div {
  font-weight: bold;
}

.StickyBottom {
  position: -webkit-sticky;
  position: sticky;
  left: 50%;
  bottom: 64px;
  z-index: 1000;

  transform: translateX(-50%);
}

.CustomInput {
  border: 1px solid #d9d9d9;
  padding: 0 8px;
  border-radius: 4px;
}

input::placeholder {
  color: #d9d9d9;
}

.CustomTable {
  padding: 16px;
}

.CustomTable h2 {
  font-size: 28px;
}

.CustomTable table {
  margin-top: 16px;
  border: 1px solid #d9d9d9;
  width: 100%;
}

.CustomTable table th,
.CustomTable table tr,
.CustomTable table td {
  padding: 8px;
  border: 1px solid #d9d9d9;
}

.CustomTable button {
  background-color: deepskyblue;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0 4px;
  transition: all 0.3s;
}

.Tag {
  margin: 0 8px;
  padding: 4px 8px;
  background-color: whitesmoke;
  border-radius: 4px;

  font-size: 12px;
}

/* -----  old css  ----- */
.MegaTabla-Head {
  margin-bottom: -8px;
}

.MegaTabla-Head h1 {
  font-size: 22px;
  margin: -8px 0 8px 8px;
  padding: 0;
}

.MegaTabla table {
  width: 150%;
  position: relative;
}

.MegaTabla tbody tr:nth-child(odd) {
  background: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.MegaTabla td {
  padding: 4px;
  border-right: 1px solid #eee;
  font-weight: normal;
}

.MegaTabla th {
  background-color: deepskyblue;
  color: white;
  padding: 8px;
  font-weight: 600;
}

.MegaTabla .MegaTablaBody {
  border: 1px solid #eee;
  border-radius: 8px;
  overflow-x: scroll;
}

.MegaTabla tbody td:first-child {
  background-color: #fafafa;
}

.MegaTabla tbody td:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 44ch;
  border-right: none;
}

.MegaTabla tbody td:first-child {
  background-color: #fafafa;
}

.MegaTabla tbody tr:hover {
  background-color: rgba(0, 191, 255, 0.1) !important;
}

.MegaTabla-Paginado ul {
  list-style: none;
  padding: none;
  display: flex;
}

.MegaTabla-Paginado ul li {
  background-color: white;
  margin: 2px;
  width: 28px;
  height: 28px;
  line-height: 25px;
  border: 1px solid #eee;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  font-weight: normal;
}

.MegaTabla-Paginado ul li:hover {
  border: 1px solid deepskyblue;
  color: deepskyblue;
}

/* tabla fija */

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
}

.table-scroll table {
  border-collapse: separate;
  border-spacing: 0;
}

.table-wrap {
  position: relative;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border-bottom: 1px solid #eee;
}

.table-scroll thead th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

/* safari and ios need the tfoot itself to be position:sticky also */

.table-scroll th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: deepskyblue !important;
}

.table-scroll td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #fafafa !important;
}

.table-scroll thead th:first-child,
.table-scroll tfoot th:first-child {
  z-index: 5;
}

/* --- barras --- */

.level {
  width: 100%;
  /* Full width */
  background-color: #f5f5f5;
  /* Grey background */
  border-radius: 8px;
  margin-top: 5px;

  overflow: hidden;
}

.skill {
  text-align: right;
  /* Right-align text */
  padding-top: 5px;
  /* Add top padding */
  padding-bottom: 5px;
  /* Add bottom padding */
  color: white;
  /* White text color */
}

.PetshopDetails h5 {
  font-size: 16px;
  margin-bottom: 8px;
}

.SimulaTitulo {
  display: block;
  background-color: transparent;
  height: 22px;
}

.PetshopDetails fieldset,
.PetshopPays fieldset {
  margin: 0;
  width: 100%;
}

.PetshopDetails fieldset input {
  margin-right: 8px;
  line-height: 36px;
}

.PetshopDetails .MegaButton {
  margin: 0 !important;
}

.PetshopPays .GridCuatro {
  margin-bottom: 16px;
}

.PetshopPays .GridCuatro .MegaButton {
  margin: 0;
  line-height: 28px;
}

h5 {
  font-size: 14px !important;
  margin-bottom: 8px;
  font-weight: normal;
}

.ant-input {
  /* height: 30px !important; */
  width: 100%;
}

.anticon.anticon-search.ant-input-search-icon svg {
  margin-bottom: 0px !important;
}

.ant-card-body {
  padding: 0px 10px 0px 10px !important;
}

.ant-card {
  border-radius: 10px !important;
}

.ant-calendar-picker {
  width: 100%;
}

.ProductosMP .Aux {
  margin: -8px -8px 0 -8px;
}

.ProductosMP img,
.FijarPrecios img,
.ProductosVariationList img {
  width: auto;
  max-height: 64px;
}

.ListOrder .TableBox article,
.Outsourcedlogistics .TableBox article {
  box-shadow: none;
}

.ListOrder .TableBody {
  margin-bottom: -32px;
}

.ListOrder .Aux,
.LabelSpecial {
  margin-left: -12px;
  padding: 8px 0;
  border-radius: 4px;
  font-size: 16px;
  color: #414141;
}

.LabelSpecial {
  margin-left: 0;
}

.PetCoverage h5 {
  margin-bottom: 8px;
}

.PetCoverage .GridDosOctavos {
  width: calc(100% - 16px);
  margin: 0;
}

.PetCoverage li {
  list-style: none !important;
}

.MapaCobertura {
  height: calc(100vh - 17rem);
  /* margin-bottom: -1rem; */
  z-index: 1 !important;
}

.Aux .MapaCobertura {
  height: 60vh;
  margin-bottom: 0;
}

/************************/

select {
  display: block;
  font-size: 16px;
  font-family: "Arial", sans-serif;
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.5em 1.5em 0.4em 0.9em;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #fff 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

select::-ms-expand {
  display: none;
}

select:hover {
  border-color: #888;
}

select:focus {
  border-color: #aaa;
  outline: none;
}

select option {
  font-weight: normal;
}

input::-webkit-calendar-picker-indicator {
  display: none !important;
}

/************************/

.ant-btn {
  height: 38px;
}

.TableBoxHeader span button,
td .ant-btn {
  width: 100%;
  height: 32px;
  line-height: 18px;
  /* background-color: #f8f8f8; */
  /* border: 1px solid #ddd; */

  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 0.8rem;
}

.TableBoxHeader span button {
  color: #414141;
  height: auto;
  line-height: 18px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  margin: -8px 0;
}

.ant-btn i {
  font-size: 0.8rem;
  display: none;
}

.FijarPrecios fieldset {
  width: 100%;
  margin: 0;
}

.Span13 {
  grid-column: 1 / 3;
}

.leaflet-container {
  z-index: 0 !important;
}

.PetshopFIlterDay .botonItem {
  margin: 8px 0 8px 8px;
  padding: 6px 16px;
}

.TableBody {
  margin-top: 8px;
}

.Loadin {
  width: 100%;
  height: 100%;

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.fullHeight {
  min-width: 100vw;
  min-height: 80vh;
}

.Loadin svg {
  font-size: 2rem;
  color: deepskyblue;
  animation: giro360 0.5s infinite linear;
}

.PetshopReportStatics .Card h4 {
  font-size: 22px;
  margin-bottom: 8px;
}

.Notificaciones p {
  width: 50%;
  text-align: right;
}

.TextoEspecial {
  padding-left: 8px;
  color: #1fa9e7;
}

.Sticky {
  position: fixed;
  top: 90px;
  width: 23%;
}

.DashboardReportes .Card {
  cursor: pointer;
  height: 74px;
  margin: 18px 0 8px 0;
  transition: all 0.3s;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;
}

.DashboardReportes .Card:hover {
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
}

.DashboardReportes .Card h6 {
  text-align: center;
  font-size: 0.9rem;
}

.mapPetshopZone {
  z-index: 1;
}

/************************/

.SuscriptionPage .aux .MegaButton {
  margin: 0;
}

.TablaControlada {
  border-radius: 4px;
  margin-bottom: -8px;
}

.TablaControlada .ant-table {
  overflow: scroll;
  max-height: calc(100vh - 356px);
  border: 1px solid #eee;
}

.ant-table-wrapper {
  border: none;
}

.MisPichosOrders .TablaControlada .ant-table::-webkit-scrollbar-corner {
  height: 0;
}

.MisPichosOrders .TablaControlada .ant-table::-webkit-scrollbar-thumb {
  height: 0;
}

.MisPichosOrders .downloadToExcel {
  margin: -64px 0 -4px 0;
}

.MisPichosOrders .GridTres {
  margin-top: -4px;
}

.MisPichosOrders .GridTres .GridDos {
  align-items: center;
}

/* === ================================ === */

.omi_img_input_file {
  color: transparent;
  opacity: 1;
  width: 100%;
  height: 40px;
  margin-top: 8px;
  cursor: pointer;
}

.omi_img_input_file::-webkit-file-upload-button {
  visibility: hidden;
}

.omi_img_input_file::before {
  margin: 0 0 -32px 0;
  content: "Elegir imagen";
  border-radius: 4px;
  line-height: 36px;
  cursor: pointer;
  color: #1fa9e7;
  display: block;
  font-size: 16px;
  text-align: center;
  border: 1px solid #1fa9e7;
  transition: all 0.3s;
}

.omi_img_input_file:hover::before {
  background-color: deepskyblue;
  color: white;
}

.omi_img_input_file:active {
  outline: 0;
}

.omi_img_input_file:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.CardHead {
  background-color: #fafafa;
  margin: -8px -8px 8px -8px;
  padding: 8px 8px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  border-bottom: 1px solid #f3f3f3;
}

.CardHead h4,
.CardHead h6 {
  color: #414141;
}

.CardHead h4::first-letter,
.CardHead h6::first-letter {
  text-transform: capitalize;
}

.checkboxes .RowCenter {
  margin-top: 8px;
  background-color: rgba(0, 191, 255, 0.05);
  padding: 6px 0 6px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.checkboxes label {
  margin-bottom: 0;
  cursor: pointer;
}

.checkboxes .disabled {
  opacity: 0.5;
}

.CardFixedBottom {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 8px;
  border-top: 2px solid #f3f3f3;
}

.CardHead .MegaButton {
  width: auto;
  height: 36px;
  min-width: 36px;
  margin: 0 0 0 8px;
  font-size: 18px;
}

.btnAux .MegaButton {
  font-size: 20px;
  margin: 0 2px;
  border: none;
}

.SearchInput input {
  width: 200px;
  padding-right: 12px;
}

.SearchInput .MegaButton {
  margin: 0 0 0 -8px;
  height: 36px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 8px;
}

.TagConCheck {
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  margin: 4px;
  padding: 4px 8px 4px 4px;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
}

.TagConCheck label {
  padding: 0 8px 0 0;
}

.SecondaryNav {
  width: 100vw;
  margin: -8px 0 -8px -32px;
}

.SecondaryNav .auxBox {
  padding: 0 4px;
}

.SecondaryNav .MegaButton {
  padding: 8px 16px;
}

.Timeline-List {
  margin: -16px;
  padding: 8px;

  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.Timeline-Item {
  position: relative;
}

.Timeline-Item::before {
  position: absolute;
  content: "";
  top: 100%;
  left: 32px;

  width: 2px;
  height: 100%;
  background-color: #ddd;
}

.CardHead .MegaButton svg {
  animation: none;
}

.fixed-header-table {
  position: relative;
}

.fixed-header-table .ant-table-thead {
  position: sticky;
  top: 60px;
  z-index: 1;
  height: 100px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.fixed-header-table-pricing .ant-table-thead {
  position: sticky;
  top: 63px;
  z-index: 1;
  height: 100px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.fixed-header-table-pet-products .ant-table-thead {
  position: sticky;
  top: 0px;
  z-index: 1;
  height: 100px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.ant-select-focused .ant-select-item-option {
  background-color: red;
  /* Cambia el color de fondo */
  color: #333;
  /* Cambia el color del texto */
  /* Otros estilos que desees aplicar */
}

.cucardaPreview {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(216, 216, 216);
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cuca-footer-preview {
  background-color: #22A9E7;
  color: white;
  padding: 6px 20px;
  border-radius: 4px;
  font-size: 12px;
  position: absolute;
  bottom: 10px;
}

.brand-cuca-preview {
  position: absolute;
  width: 30%;
  top: 0;
  right: 0;
}

.cucardaPreview h4 {
  margin-bottom: 33px;
}

.mar-logo-example {
  margin-bottom: 25px;
}

.multi-brands-ce .ant-select-selection--multiple {
  min-height: 110px;
}

.loading-hover {
  background: #ffffffcf;
  position: absolute;
  z-index: 123;
  width: 100%;
  height: 318px;
}


.indicator-logistic-percs {
  padding: 5px;
  background: rgb(60, 128, 60);
  width: fit-content;
  border-radius: 10px;
  color: white;
}

.indicator-logistic-percs-danger {
  padding: 5px;
  background: rgb(142, 56, 56);
  width: fit-content;
  border-radius: 10px;
  color: white;
}

.prvMultiPicProgressBar {
  position: absolute;
  background: #ffffffd9;
  width: 100%;
  /* margin: auto; */
  display: flex;
  justify-content: center;
  height: 100%;
  z-index: 9999;
  align-items: center;
}

.quill {
  height: 230px !important;
  margin-bottom: 50px !important;
}

.catalogue-prod-inactive-row {
  background-color: rgba(210, 72, 72, 0.102);
}

.active-green {
  background-color: #64cf8a !important;
  color: white !important;
}

.catalogue-card {
  background-color: white;
  border-radius: 10px;

}

.catalogue-card:hover {
  transition: 0.4s ease-in-out;
  border-radius: 10px;
  background-color: rgba(175, 235, 255, 0.654);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}