.GridDosTerceos {
  text-align: initial;
}

.Embudo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -24px 0 0 -32px;
}

.Embudo .Card {
  margin: 8px 0 0 0;
}

.Embudo .Card:nth-child(1) {
  width: 100%;
}

.Embudo .Card:nth-child(2) {
  width: 90%;
}

.Embudo .Card:nth-child(3) {
  width: 80%;
}

.Embudo .Card:nth-child(4) {
  width: 70%;
}

.Embudo .Card:nth-child(5) {
  width: 60%;
}

.Embudo .Card:nth-child(6) {
  width: 50%;
}

.Embudo .Card:nth-child(7) {
  width: 40%;
}

.Embudo .Card:nth-child(8) {
  width: 30%;
}

/* === === === === === === === === === === === === === === === */

.AllRegisters {
  background-color: transparent;
  height: calc(100vh - 164px);
  margin: -32px -32px -32px 0;
  padding: 0 16px;

  overflow-y: scroll;
}

.AllRegisters::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* === === === === === === === === === === === === === === === */

.PetshopList {
  list-style: none;
}

.PetshopListItem {
  background-color: #fafafa;
  margin: 5px 0px;
  padding: 8px;
  border: 1px solid #eee;
}

/* === === === === === === === === === === === === === === === */