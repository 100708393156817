.CompraSeleccionada{
  display: grid;
  grid-template-columns: 79% 19%;
  align-items: flex-start;
  justify-content: center;
  column-gap: 16px;
  padding: 8px 24px;
}

.Desktop .ColDuo{
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 16px;
}

.CompraSeleccionada .Card{
  padding: 2px 8px;
  box-shadow: 0 5px 5px -3px rgba(105, 105, 105, .3);
}

.InfoRight{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
}

/* Lista de productos que estan incluidos en la compra/pedido */
.compraResumenListaProductos{
  display: grid;
  grid-template-columns: 48px auto 50px;

  margin-bottom: 1rem;
}

.compraResumenListaProductos img{
  max-width: 40px;
}

.UserHappines{
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  margin-bottom: 16px;
  background-color: #fafafa;
  padding: 8px 4px 8px 8px;
  margin: 8px 0 0 0;
  border-radius: 8px;
}

.UserHappines h6{
  margin: 0;
  padding: 0;
  font-weight: normal;
  margin-right: 8px;
  font-size: 14px;
}

/*--- Detalle de la compra, Petshop, estado, id de compra y movimientos ---*/

.compraResumenAlert{
  display: flex;
  flex-direction: column !important;
  padding: 8px;
  border-radius: 8px !important;
  box-shadow: none;
  position: relative;
  margin-top: 6px;
}

.CompraSeleccionada svg{
  margin-right: 8px;
}

.compraResumenAlert svg{
  font-size: 22px;
  cursor: pointer;
}

.stickersTagBox{
  margin: -8px -8px 4px -4px;
}

/* Stickers informativos de la compra */

.blanco .SolicitaDineroIcon,.blanco .ReembolsoIcon{
  color: lightslategray !important;
}

.SolicitaDineroIcon,.ReembolsoIcon{
  width: 24px;
  height: 24px;
  position: relative;
  background-color: transparent;
}

.SolicitaDineroIcon svg,
.ReembolsoIcon svg{
  font-size: 24px;
  margin: -3px 0 0 -4px;
}

.SolicitaDineroIcon .aux,
.ReembolsoIcon .aux{
  position: absolute;
  top: -4px;
  right: -2px;
  transform: rotate(45deg);
  font-size: 16px !important;
}

/* ======================================== */

/*- Lista de informacion de la compra, Cliente, Fecha de entrega , etc... -*/
.compraResumenLista h6{
  font-size: 14px;
  margin:0;
}
.compraResumenLista .compraAccionBtn{
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  cursor: pointer;
  font-size: 28px;
  color: deepskyblue;
}

.ItemResumenLista{
  position: relative;
  background-color: #fafafa;
  padding: 8px;
  border-radius: 8px;
  margin: 8px 0 4px 0;
}



.GridDos .MegaButton{
  margin: 8px 0 0 0;
}

.MegaButton div{
  font-size: .75rem;
}

.GridDos{
  background-color: transparent;
}

.PB{
  padding-bottom: 8px;
}


.compraResumenAlert h4{
  margin-bottom: 16px;
}
