.stop {
    background-color: red;
    width: 100px;
    height: 100px;
    border: 5px solid #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}