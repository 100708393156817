.Loadin {
  width: 100%;
  height: 100%;

  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSmall {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullHeight {
  min-width: 100vw;
  min-height: 80vh;
}

.Loadin svg {
  font-size: 2rem;
  color: deepskyblue;
  animation: giro360 .5s infinite linear;
}

.loadingSmall svg {
  font-size: 2rem;
  color: deepskyblue;
  animation: giro360 .5s infinite linear;
}