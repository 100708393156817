.TagList{
  margin: 8px;
  position: relative;
}

.TagList input,
.TagBox{
  background-color: white;
  height: 32px;
  padding: 8px 0;
  border: none;
}

.TagBox{
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.TagList input{
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;

  padding: 8px;
}

.TagList input::placeholder{
  color: #a1a1a1;
}

.TagList input:focus{
  outline: none;
}

.TagBox{
  display: flex;
  align-items: center;
}

.TagItem{
  margin: 0 4px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0 6px 2px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  border-radius: 4px;
  font-size: 12px;

  animation: xpand .3s linear;

}

.TagItem svg{
  transform: rotate(45deg);
  margin: 2px 2px 0 4px;
  color: #a1a1a1;
}

.SelectTag{
  background-color: white;
  width: 100%;

  position: absolute;
  top: 115%;
  border-radius: 4px;
  list-style: none;

  animation: xpand .3s linear;
}

@keyframes xpand {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


.SelectTag li{
  border: 1px solid #f8f8f8;
  margin: 8px;
  padding: 8px;

  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;

  text-transform: capitalize;
  transition: all .3s;
}

.SelectTag li:hover{
  background-color: #fafafa;
}

.SelectTag svg{
  font-size: 18px;
  margin-right: 8px;
  color: #ccc;
  transition: all .3s;
}

.SelectTag .activo svg{
  color: deepskyblue;
}

/* ------------------------------------------ */

.Danger{
  border-color: tomato;
  background-color: tomato;
  color: white;
}

.Warning{
  border-color: gold;
  background-color: gold;
  color: #414141;
}

.Primary{
  border-color: deepskyblue;
  background-color: deepskyblue;
  color: white;
}

.Success{
  border-color: mediumseagreen;
  background-color: mediumseagreen;
  color: white;
}

.OutDanger{
  border-color: tomato;
  background-color: transparent;
  color: tomato;
}

.OutWarning{
  border-color: gold;
  background-color: transparent;
  color: gold;
}

.OutPrimary{
  border-color: deepskyblue;
  background-color: transparent;
  color: deepskyblue;
}

.OutSuccess{
  border-color: mediumseagreen;
  background-color: transparent;
  color: mediumseagreen;
}