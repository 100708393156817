.botonParaDescargarExcel span button,
.MegaButton {
    width: 100%;
    height: 36px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    margin: 8px 0;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-transform: capitalize;
    border-radius: 4px;
    font-size: 0.8rem;
    transition: all 0.1s;
}

.MegaButton .loading {
    animation: Giro360 1s infinite linear;
}

@keyframes Giro360 {
    0% {
        transform: rotate(0);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.success {
    border-color: mediumseagreen;
    background-color: mediumseagreen;
    color: white;
}

.danger {
    border-color: tomato;
    background-color: tomato;
    color: white;
}

.warning {
    border-color: gold;
    background-color: gold;
    color: #414141;
}

.primary {
    border-color: deepskyblue;
    background-color: deepskyblue;
    color: white;
}

.Success {
    border-color: mediumseagreen;
    background-color: mediumseagreen;
    color: white;
}

.outline-danger {
    border-color: tomato;
    background-color: transparent;
    color: tomato;
}

.outline-warning {
    border-color: gold;
    background-color: transparent;
    color: gold;
}

.outline-primary {
    border-color: deepskyblue;
    background-color: transparent;
    color: deepskyblue;
}

.outline-success {
    border-color: mediumseagreen;
    background-color: transparent;
    color: mediumseagreen;
}

.notificaciones .MegaButton {
    width: auto;
    min-width: 100px;
    padding: 2px;
    margin-bottom: 0;
}

.outline-primary:hover {
    background-color: deepskyblue;
    color: white;
}

.outline-danger:hover {
    background-color: tomato;
    color: white;
}

.outline-success:hover {
    background-color: mediumseagreen;
    color: white;
}

.outline-warning:hover {
    background-color: goldenrod;
    color: white;
}

.Separados .MegaButton {
    margin-left: 8px;
}

/*Button para subir imagenes al wizzard*/

.button {
    padding: 8px;
    margin: 5px;
    color: white;
    border: none;
    border-radius: 5px;
}

.button.upload {
    background-color: deepskyblue;
}

.button.remove {
    background-color: tomato;
}